<template>
    <div>
        <div class="banner">
            <!-- https://iv.vu818.com/video/video.mp4 -->
            <template v-if="getAdvData(55).children">
                <a :href="getAdvData(55).children[0].url" target="_blank" rel="noopener noreferrer">
                    <img v-if="type(getAdvData(55).children[0].image) == 'img'" :src="getAdvData(55).children[0].image" />
                    <video v-else :src="getAdvData(55).children[0].image" autoplay loop muted width="100%"></video>
                </a>
            </template>
            <div class="box w">
                <div class="seach">
                    <div
                        v-for="el in Data"
                        :key="el.id"
                        @click="topActive = el.id"
                        :class="{ navBg: topActive == el.id }"
                        :style="{ 'background-image': `url(${topActive == el.id || hoverId == el.id ? el.bg_img : ''})` }"
                        @mouseenter="hoverId = el.id"
                        @mouseleave="hoverId = null"
                    >
                        <p>{{ el.name }}</p>
                        <p>{{ el.reason }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="big">
            <div class="box2 w">
                <div class="title">
                    <div class="w1 bor-r">功能模块</div>
                    <div class="w2 bor-r">是否支持</div>
                    <div class="w3">说明</div>
                </div>
                <div class="nav" v-for="item in activeObj.second" :key="item.id">
                    <div class="nav-top w4 bor-r z">
                        <div style="padding: 20px 0">{{ item.name }}</div>
                    </div>
                    <div class="nav-box">
                        <div class="content" v-for="el in item.third" :key="el.id" :id="item.id + '-' + el.id">
                            <template v-if="el.status">
                                <div class="w5 h bor-r p1">{{ el.content }}</div>
                                <div class="w2 h bor-r c">
                                    <div>
                                        <img
                                            :style="el.support ? 'top: -103px;left: -1000px;' : 'top: -1671px;left: -249px;'"
                                            src="https://iv.vu818.com/img/vu818.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div class="w3 h p2">{{ el.reason }}</div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="searchMod">
            <input type="search" v-model.trim="searchValue" @keydown.enter="searchPlay(searchValue)" />
            <div @click="searchPlay(searchValue)"></div>
            <template v-if="seachActive >= 0">
                <span>{{ seachActive + 1 }}/{{ seachArr.length }}</span>
                <div @click="next"></div>
                <div @click="prev"></div>
                <div @click="clear"></div>
            </template>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { imgs, videos } from "@/components/customs/format";

export default {
    inject: ["getAdvData"],
    data() {
        return {
            // 存储的是id
            topActive: "",
            Data: [
                {
                    name: "pc",
                    data: [
                        {
                            moduleName: "商家入驻",
                            list: [
                                {
                                    id: "pc1",
                                    name: "pc",
                                    info: "",
                                    status: false,
                                },
                                {
                                    id: "pc1",
                                    name: "商家入驻申请流程",
                                    info: "独立商家申请页，严格有效的入驻流程及审核机制",
                                    status: false,
                                },
                                {
                                    id: "pc1",
                                    name: "商家店铺自定义装修功能",
                                    info: "自定义店铺商品分类、店铺装修DIY",
                                    status: false,
                                },
                                {
                                    id: "pc1",
                                    name: "多套店铺模板选择",
                                    info: "默认6套精美店铺模板可供选择",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家店铺街展示",
                                    status: true,
                                    info: "支持",
                                },
                                {
                                    id: "pc1",
                                    name: "商家独立店铺功能",
                                    info: "独立店铺列表页展示，增加店铺曝光率",
                                    status: true,
                                },
                            ],
                            id: "module1",
                        },
                        {
                            moduleName: "首页",
                            list: [
                                {
                                    id: "pc1",
                                    name: "pc",
                                    info: "",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家入驻申请流程",
                                    info: "独立商家申请页，严格有效的入驻流程及审核机制",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家店铺自定义装修功能",
                                    info: "自定义店铺商品分类、店铺装修DIY",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "多套店铺模板选择",
                                    info: "默认6套精美店铺模板可供选择",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家店铺街展示",
                                    status: true,
                                    info: "支持",
                                },
                                {
                                    id: "pc1",
                                    name: "商家独立店铺功能",
                                    info: "独立店铺列表页展示，增加店铺曝光率",
                                    status: true,
                                },
                            ],
                            id: "module2",
                        },
                        {
                            moduleName: "分类页",
                            list: [
                                {
                                    id: "pc1",
                                    name: "pc",
                                    info: "",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家入驻申请流程",
                                    info: "独立商家申请页，严格有效的入驻流程及审核机制",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家店铺自定义装修功能",
                                    info: "自定义店铺商品分类、店铺装修DIY",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "多套店铺模板选择",
                                    info: "默认6套精美店铺模板可供选择",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家店铺街展示",
                                    status: true,
                                    info: "支持",
                                },
                                {
                                    id: "pc1",
                                    name: "商家独立店铺功能",
                                    info: "独立店铺列表页展示，增加店铺曝光率",
                                    status: true,
                                },
                            ],
                            id: "module3",
                        },
                        {
                            moduleName: "列表页",
                            list: [
                                {
                                    id: "pc1",
                                    name: "pc",
                                    info: "",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家入驻申请流程",
                                    info: "独立商家申请页，严格有效的入驻流程及审核机制",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家店铺自定义装修功能",
                                    info: "自定义店铺商品分类、店铺装修DIY",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "多套店铺模板选择",
                                    info: "默认6套精美店铺模板可供选择",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家店铺街展示",
                                    info: "支持",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家独立店铺功能",
                                    info: "独立店铺列表页展示，增加店铺曝光率",
                                    status: true,
                                },
                            ],
                            id: "module4",
                        },
                        {
                            moduleName: "详情页",
                            list: [
                                {
                                    id: "pc1",
                                    name: "pc",
                                    info: "",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家入驻申请流程",
                                    info: "独立商家申请页，严格有效的入驻流程及审核机制",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家店铺自定义装修功能",
                                    info: "自定义店铺商品分类、店铺装修DIY",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "多套店铺模板选择",
                                    info: "默认6套精美店铺模板可供选择",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家店铺街展示",
                                    info: "支持",
                                    status: true,
                                },
                                {
                                    id: "pc1",
                                    name: "商家独立店铺功能",
                                    info: "独立店铺列表页展示，增加店铺曝光率",
                                    status: true,
                                },
                            ],
                            id: "module5",
                        },
                    ],
                },
                {
                    name: "wap",
                    data: [],
                },
                {
                    name: "wx",
                    data: [],
                },
                {
                    name: "app",
                    data: [],
                },
            ],
            searchValue: "",
            seachArr: [],
            seachActive: -1,
            hoverId: null,
        };
    },
    async created() {
        this.Data = await this.getData();
        this.topActive = this.$route.query.id;
        console.log(this.getAdvData(55));
    },
    methods: {
        searchPlay(val) {
            val = val.replaceAll(" ", "");
            if (!val) return; //输入框为空时
            //在连续搜索的情况下清除一次
            if (this.seachActive !== -1) {
                this.seachArr.forEach(el => {
                    document.getElementById(el).classList.remove("active");
                });
                this.seachArr = [];
                this.seachActive = -1;
            }
            // 匹配查找操作
            let arr = [];
            this.activeObj.second.forEach(el => {
                el.third.forEach(element => {
                    let id = el.id + "-" + element.id;
                    if (element.content.indexOf(val) !== -1 || element.reason.indexOf(val) !== -1) {
                        arr.push(id);
                    }
                });
            });
            if (!arr[0]) {
                //没有找到任何匹配的一项时
                return this.$message.error({ message: "未搜索到匹配项！！", offset: 100 });
            }
            arr.forEach(el => {
                document.getElementById(el).classList.add("active"); //添加高亮
            });
            this.seachArr = arr;
            this.seachActive = 0;
        },
        next() {
            // 最后一个时
            if (this.seachActive === this.seachArr.length - 1) return (this.seachActive = 0);
            this.seachActive++;
        },
        prev() {
            // 第一个时
            if (this.seachActive === 0) return (this.seachActive = this.seachArr.length - 1);
            this.seachActive--;
        },
        clear() {
            // 清除操作
            this.seachArr.forEach(el => {
                document.getElementById(el).classList.remove("active");
            });
            this.seachArr = [];
            this.seachActive = -1;
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        // 获取所有数据
        async getData() {
            let { data } = await axios.get("/api/price/show");
            return data;
        },
        type(url = "") {
            for (let i = 0; i < imgs.length; i++) {
                const e = imgs[i];
                if (url.indexOf(e) != -1) {
                    return "img";
                }
            }
            for (let i = 0; i < videos.length; i++) {
                const e = videos[i];
                if (url.indexOf(e) != -1) {
                    return "video";
                }
            }
            return "";
        },
    },
    computed: {
        activeObj() {
            // console.log(this.Data, this.topActive);
            return this.Data.find(el => el.id == this.topActive) || {};
        },
    },
    watch: {
        seachActive(newVal) {
            // 不监听清除操作
            if (newVal === -1) return;
            // 滑动至指定位置
            let div = document.getElementById(this.seachArr[newVal]);
            window.scrollBy({
                top: div.getBoundingClientRect().top - 90,
                behavior: "smooth",
            });
        },
        topActive(newVal) {
            // 顶部切换清除一次
            this.clear();
        },
    },
};
</script>
<style lang="less" scoped>
* {
    box-sizing: border-box;
}
.searchMod {
    position: fixed;
    height: 40px;
    top: 24px;
    left: 334px;
    z-index: 2015;
    font-size: 12px;
    box-shadow: 1px 1px 10px 1px gainsboro;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 10px;
    input {
        border: none;
        border-right: 1px solid #858ef3;
        width: 120px;
        margin-right: 5px;
        padding: 0 5px;
        // background-color: rgba(133, 142, 243, 0.15);
    }

    div {
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: url(https://iv.vu818.com/img/vu818.png) no-repeat;
        background-size: 1000px;
    }
    div:nth-of-type(1) {
        background-position: -365px -440px;
    }
    div:nth-of-type(2) {
        height: 20px;
        background-position: -100px -575px;
    }
    div:nth-of-type(3) {
        height: 20px;
        background-position: -100px -598px;
    }
    div:nth-of-type(4) {
        width: 24px;
        background-position: -133px -1064px;
    }
}
.active {
    background-color: rgba(133, 142, 243, 0.15);
    &:hover {
        background-color: #858ef3;
        color: white;
    }
}
.w {
    margin: auto;
    width: 1200px;
}
.big {
    width: 100%;
    margin-bottom: 30px;
}
.banner {
    position: relative;
    a {
        display: block;
        width: 100%;
        & > img,
        & > video {
            width: 100%;
        }
    }
}
.box {
    border-radius: 15px;
    padding: 5px;
    background-color: hsla(0, 0%, 100%, 0.8);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    width: 1200px;
    // box-shadow: 10px 6px 10px 5px black;
}
.seach {
    display: flex;
    width: 100%;
    height: 80px;
    .navBg {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
    }
    div {
        flex: 1;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        &:hover {
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
        }
        p:nth-child(1) {
            font-size: 14px;
            font-weight: 700;
        }
        p:nth-child(2) {
            font-size: 12px;
        }
    }
}
.box2 {
    margin-top: 20px;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    overflow: hidden;
}
.title {
    display: flex;
    height: 45px;
    font-size: 16px;
    line-height: 45px;
    border-bottom: 1px solid #dcdcdc;
    text-align: center;
    position: relative;
}
.nav {
    display: flex;
    font-size: 16px;
}
.nav-box {
    display: flex;
    flex-wrap: wrap;
    width: 1131px;
    text-align: left;
    // height: 100%;
}
.content {
    display: flex;
    // height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #dcdcdc;
    &:hover {
        background-color: #858ef3;
        color: white;
    }
}
.w1 {
    width: 425px;
}
.w2 {
    width: 157px;
}
.w3 {
    width: 621px;
}
.bor-r {
    border-right: 1px solid #dcdcdc;
}
.w4 {
    width: 68px;
}
.w5 {
    width: 355px;
}
.w6 {
    width: 177px;
}
.z {
    writing-mode: vertical-lr;
    text-align: center;
    line-height: 65px;
}
.p1 {
    padding-left: 30px;
}
.p2 {
    padding-left: 50px;
    padding-right: 10px;
}
.c {
    text-align: center;

    & > div {
        position: relative;
        width: 28px;
        height: 25px;
        margin: 10px auto;
        overflow: hidden;
        & > img {
            position: absolute;
        }
    }
}
.h {
    height: 100%;
}
.nav-top {
    // max-height: 100%;
    // height: 100%;
    background-color: #cddbf954;
    border-bottom: 1px solid #dcdcdc;
}
</style>
